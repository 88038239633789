import React from "react";
import { Navigate } from "react-router-dom";

export const AdminRoute = ({ userType, children }) => {
  return userType === "admin" ? children : <Navigate to="/" />;
};

export const RecruitmentManagerRoute = ({ userType, children }) => {
  return userType === "recruitment-manager" ? children : <Navigate to="/" />;
};

export const CandidateRoute = ({ userType, children }) => {
  return userType === "candidate" ? children : <Navigate to="/" />;
};

export const LoggedInRoute = ({ userStatus, children }) => {
  return userStatus ? <Navigate to="/" /> : children;
};
