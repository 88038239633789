import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.js";
import "./assets/scss/themes.scss";
import "./App.css";
import "react-phone-number-input/style.css";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import create from "zustand";
import { persist, devtools } from "zustand/middleware";
import {
  RecruitmentManagerRoute,
  AdminRoute,
  CandidateRoute,
} from "./components/common/admin-route.component";
import useAxios from "./api/useAxios";
import { useQuery } from "react-query";
import { useState, useEffect, lazy, Suspense } from "react";
import ScrollToTop from "./components/common/scroll-to-top.component";
import "react-quill/dist/quill.snow.css";
// Public View Imports


import ReactGA from "react-ga4";



// Admin Dashboard Imports
const AdminLayout                     = lazy(() => import("./layout/admin-dash"));
const AdminDashPage                   = lazy(() => import("./pages/admin-dash/dashboard-page"));
const MonthlyStatsPage                = lazy(() => import("./pages/admin-dash/monthly-stats"))
const AdminActiveCompanies            = lazy(() => import("./pages/admin-dash/companies/active-companies-page"));
const SuspendedCompanies              = lazy(() => import("./pages/admin-dash/companies/suspended-companies-page"));
const AdminListOfSkills               = lazy(() => import("./pages/admin-dash/skills/skills-list-page"));

const ProfileCompletion               = lazy(() => import("./pages/candidate-dash/auth/profile-completion"));


//Trainer Dashboard Imports
const ViewCandidatesPage              = lazy(() => import("./pages/admin-dash/candidates/ViewCandidates/list-of-candidates-page"));
const CandidateDetailsPage            = lazy(() => import("./pages/admin-dash/candidates/candidate-details-page"));
const CompanyJobsView                 = lazy(() => import("./pages/admin-dash/companies/viewJobs/view-jobs-page"));
const AdminJobDetail                  = lazy(() => import("./pages/admin-dash/jobs/job-detail-page"));
const CampusAmbassadorsAdminPage      = lazy(() => import('./pages/admin-dash/campus-ambassadors/campus-ambassadors.page'));
const AmbassadorsReferralsAdminPage   = lazy(() => import('./pages/admin-dash/campus-ambassadors/ambassador-referrals'));
const NotFoundPage                    = lazy(() => import("./pages/notFound.page"))

// public view pages
const PublicLayout                    = lazy(() => import("./layout/public-view"));
const SignInPage                      = lazy(() => import("./pages/public-view/authentication/sign-in.page"));


const { Spinner } = require("reactstrap/esm");
ReactGA.initialize("G-WVRK6VDMGM");


export const useStore = create(
  devtools(
    persist(
      (set) => ({
        userStatus: false,
        accessToken: null,
        refreshToken: null,
        userType: null,
        name: null,
        phone: null,
        email: null,
        profile_complete: null,
      }),
      {
        name: "auth-storage",
        getStorage: () => localStorage,
      }
    )
  )
);

function App() {

  const userCategory = useStore((state) => state.userType);

  return (
    <Router>
      <div className="App">
        <ScrollToTop>
          <Suspense fallback={<div style={{ 'height': '100vh', 'display': 'flex', 'alignItems': 'center', 'justifyContent': 'center' }}>
            <Spinner type="grow" color="dark" />

          </div>}>
            <Routes>
              {/* =========== Public View Routes =========== */}

              <Route path="*" element={<NotFoundPage />} />

              <Route element={<PublicLayout />}>
                <Route path="/" element={<SignInPage />} />
              </Route>

              {/* =========== Admin Dashboard Routes =========== */}

              <Route element={<AdminLayout />}>
              
                <Route
                  path="/recruitment-manager/dashboard"
                  element={
                    <RecruitmentManagerRoute userType={userCategory}>
                      <AdminDashPage />
                    </RecruitmentManagerRoute>
                  }
                />


                <Route
                  path="/recruitment-manager/monthly-stats"
                  element={
                    <RecruitmentManagerRoute userType={userCategory}>
                      <MonthlyStatsPage userCategory={userCategory} />
                    </RecruitmentManagerRoute>
                  }
                />


                <Route
                  path="/recruitment-manager/active-companies"
                  element={
                    <RecruitmentManagerRoute userType={userCategory}>
                      <AdminActiveCompanies />
                    </RecruitmentManagerRoute>
                  }
                />


                <Route
                  path="/recruitment-manager/suspended-companies"
                  element={
                    <RecruitmentManagerRoute userType={userCategory}>
                      <SuspendedCompanies />
                    </RecruitmentManagerRoute>
                  }
                />

                <Route
                  path="/recruitment-manager/list-of-skills"
                  element={
                    <RecruitmentManagerRoute userType={userCategory}>
                      <AdminListOfSkills />
                    </RecruitmentManagerRoute>
                  }
                />

                <Route
                  path="/recruitment-manager/list-of-candidates"
                  element={
                    <RecruitmentManagerRoute userType={userCategory}>
                      <ViewCandidatesPage />
                    </RecruitmentManagerRoute>
                  }
                />


                <Route
                  path="/recruitment-manager/candidate-details/:candidateID"
                  element={
                    <RecruitmentManagerRoute userType={userCategory}>
                      <CandidateDetailsPage />
                    </RecruitmentManagerRoute>
                  }
                />


                <Route
                  path="/recruitment-manager/company/:companyID"
                  element={
                    <RecruitmentManagerRoute userType={userCategory}>
                      <CompanyJobsView />
                    </RecruitmentManagerRoute>
                  }
                />

                <Route
                  path="/recruitment-manager/job/:jobID"
                  element={
                    <RecruitmentManagerRoute userType={userCategory}>
                      <AdminJobDetail />
                    </RecruitmentManagerRoute>
                  }
                />


                <Route
                  path="/recruitment-manager/campus-ambassadors"
                  element={
                    <RecruitmentManagerRoute userType={userCategory}>
                      <CampusAmbassadorsAdminPage />
                    </RecruitmentManagerRoute>
                  }
                />


                <Route
                  path="/recruitment-manager/campus-ambassador-referrals"
                  element={
                    <RecruitmentManagerRoute userType={userCategory}>
                      <AmbassadorsReferralsAdminPage />
                    </RecruitmentManagerRoute>
                  }
                />


              </Route>

              <Route element={<PublicLayout />}>
                <Route
                  path="/profile-completion"
                  exact
                  element={
                    <CandidateRoute userType={userCategory}>
                      <ProfileCompletion />
                    </CandidateRoute>
                  }/>
           
              </Route>
            </Routes>
          </Suspense>
        </ScrollToTop>
      </div>
    </Router>
  );
}

export default App;